.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-bg {
	padding-top: 32px;
    padding-bottom: 32px;
    min-height: 16rem;
    background-color: rgb(247, 250, 252);
    background-image: url(https://wohnmobile-hetzner.reimo-shops.de/static/map.svg);
    background-size: cover;
    background-position: center center;
}


.App-header {

}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
